// import { ReactComponent as ToastCloseIcon } from '@common/icons/ToastClose.svg';
import { ToastWithKeyType } from '@common/store/toasts/slice';
import { addToast, removeToast } from '@common/store/toasts/slice';
import { WISHLIST_TOAST } from '@common/store/wishList/slice';
import { isMobile } from '@common/utils';
import Close from '@images/Close.svg';
import blackClose from '@images/whiteClose.svg';
// import Close from '@images/whiteClose.svg';
import classnames from 'classnames';
import React from 'react';

export interface ToastsProps {
  toasts: ToastWithKeyType[];
  addToast: typeof addToast;
  removeToast: typeof removeToast;
  quickView?: boolean;
  Details?: boolean;
  viewCart?: () => void;
  productImage?: string;
}

const Toasts: React.FC<ToastsProps> = ({ toasts, removeToast, quickView, productImage, Details }) => {
  setTimeout(() => {
    if (toasts.length && !isMobile()) {
      document.getElementById('QuickViewToasts')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, 1000);
  const isWishlist = (toast) => {
    return toast?.options?.find((option) => option?.name == WISHLIST_TOAST);
  };
  let Toastcss = '';
  let errorBg = '';
  let successBg = '';
  if (Details) {
    errorBg = 'md:bg-redVariant bg-red md:bg-opacity-100 bg-opacity-15';
    successBg = 'md:bg-greenToast bg-successBGToast bg-opacity-100';
    Toastcss =
      ' border md:border-0 text-dark text-16 md:text-white mx-4 md:mx-0 my-16 font-bold rounded-10 shadow-toast md:max-w-310';
  } else {
    errorBg = 'bg-redVariant';
    successBg = 'bg-successToast';
    Toastcss = 'text-white text-18 leading-22 my-16 mx-4 md:mx-auto rounded-10 shadow-toast md:max-w-490';
  }
  return (
    <>
      {quickView ? (
        <div className={classnames('z-999 fixed bottom-0 md:top-0 md:absolute w-full')}>
          {toasts.map((toast) => (
            <div key={toast.key} className="w-340 min-w-340 md:w-369 md:min-w-369 md:max-w-410 mx-auto">
              <div
                className={
                  toast.variant === 'bg-toast-error'
                    ? 'bg-redVariant md:w-369 md:min-w-369 md:max-w-410 text-white mb-20 md:mb-24 rounded shadow-toast'
                    : 'bg-successToast md:w-369 md:min-w-369 md:max-w-410 text-white mb-20 md:mb-24 rounded shadow-toast'
                }
              >
                <div
                  className={classnames(
                    'container mx-auto w-full font-semibold flex items-center p-10 md:p-20 text-18 justify-between',
                  )}
                  font-customisation="para-text"
                >
                  <div dangerouslySetInnerHTML={{ __html: toast.content }}></div>
                  <img
                    src={blackClose}
                    alt="Close"
                    className="mx-16 cursor-pointer h-14"
                    onClick={() => removeToast(toast.key)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div
          className={classnames('flex flex-wrap z-50 md:w-fit w-full ', {
            'md:justify-end fixed md:absolute mx-auto md:right-0 bottom-0 md:top-90': Details,
            'md:justify-center fixed md:absolute mx-auto bottom-0 md:top-90': !Details,
          })}
        >
          <div className={classnames('w-fit mx-auto md:mx-0')}>
            {toasts.map((toast) => {
              return (
                <div key={toast.key}>
                  <div className="bg-white rounded-20 w-full md:w-301 md:m-0 md:mr-30 ">
                    <div
                      className={
                        toast.variant === 'bg-toast-error'
                          ? ` ${errorBg} border-redVariant ${Toastcss}`
                          : ` ${successBg} border-successToast ${Toastcss}`
                      }
                    >
                      <div
                        className={classnames(
                          'container px-15 mx-auto w-full sm:w-300 flex font-bold items-center py-15 text-16 justify-between',
                        )}
                      >
                        {Details && !isWishlist(toast) ? (
                          <img
                            src={productImage}
                            className="flex mr-12 w-60 h-60 rounded border-white border object-contain"
                          />
                        ) : null}
                        <div className="flex flex-col mr-6 -mt-2" font-customisation="para-text">
                          <div dangerouslySetInnerHTML={{ __html: toast.content }}></div>
                          {Details && !isWishlist(toast) ? (
                            <div
                              className={classnames('text-13 mt-4 leading-16 font-semibold md:text-white', {
                                'text-red ': toast.variant === 'bg-toast-error',
                                'text-successToast': toast.variant === 'bg-toast-success',
                              })}
                            >
                              {toast.variant === 'bg-toast-error'
                                ? 'Error Adding the product'
                                : 'Successfully added to cart'}
                            </div>
                          ) : null}
                        </div>
                        {Details ? (
                          <div className="flex self-start mt-4">
                            <img
                              src={Close}
                              alt="Close"
                              className="ml-16 cursor-pointer md:hidden z-999 h-14"
                              onClick={() => {
                                removeToast(toast.key);
                              }}
                            />
                          </div>
                        ) : (
                          <img
                            src={blackClose}
                            alt="Close"
                            className="ml-16 cursor-pointer md:hidden z-999 h-14"
                            onClick={() => {
                              removeToast(toast.key);
                            }}
                          />
                        )}
                        <div className={classnames('flex flex-col ml-6 self-start mt-2 py-2')}>
                          <img
                            src={blackClose}
                            alt="Close"
                            className="cursor-pointer hidden md:block z-999 min-w-14 min-h-14"
                            onClick={() => {
                              removeToast(toast.key);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default Toasts;
